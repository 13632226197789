html, body {
  height: 100%;
  margin: 0;
  overflow-x: hidden; /* Prevent horizontal scroll */
}

.App {
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Ensure the container covers the full viewport height */
  background-color: black; /* Set the background to black */
  text-align: center;
}

.App-header {
  flex: 1; /* Allow header to grow and take up available space */
  display: flex;
  justify-content: center;
  align-items: center;
}

.App-header video {
  max-width: 60%; /* Set a smaller width */
  height: auto; /* Maintain aspect ratio */
  border-radius: 10px; /* Optional: Add some border radius */
}

.App-footer {
  background-color: black; /* Match the footer background to the overall background */
  color: white; /* Ensure the text is visible against the black background */
  padding: 20px;
  width: 100%;
  text-align: center;
  font-family: 'Helvetica', sans-serif; /* Set the font to Helvetica */
  box-sizing: border-box; /* Ensure padding is included in the width */
}

.App-footer p {
  margin: 0;
  font-size: 16px; /* Adjust font size as needed */
}

.App-footer .social-links {
  margin-bottom: 10px; /* Add space below social links */
}

.App-footer a {
  color: white; /* Ensure the links are visible */
  text-decoration: none; /* Remove underline */
  margin: 0 10px; /* Spacing between icons */
}

.App-footer a:hover {
  color: #ccc; /* Change color on hover */
}

.App-footer i {
  font-size: 24px; /* Adjust icon size as needed */
}

/* Specific styling for email */
.App-footer .email {
  font-size: 18px; /* Slightly larger font size for email */
  margin-top: 10px; /* Space above the email */
  display: block; /* Make sure it appears on its own line */
}
